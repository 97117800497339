// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
export function downloadCSV(array) {
	const link = document.createElement("a");
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv === null) return;

	const filename = "export.csv";

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute("href", encodeURI(csv));
	link.setAttribute("download", filename);
	link.click();
}

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ",";
	const lineDelimiter = "\n";
	const keys = Object.keys(array[0]);

	result = "";
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;
			if (key === "desc") {
				result += '"' + item[key] + '"';
			} else {
				result += item[key];
			}
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

export const StateEnum = {
	Inactivo: 0,
	Activo: 1,
	Eliminado: 2,
};

export const CardTypeEnum = {
	Desconocido: 0,
	Visa: 1,
	Mastercard: 2,
	AmericanExpress: 3,
};

export const CardBankEnum = {
	Desconocido: 0,
	CapitalOne: 1,
	BankOfAmerica: 2,
	OceanBank: 3,
};

export const StateEnumUpd = {
	Inactivo: "Inactivo",
	Activo: "Activo",
	Eliminado: "Eliminado",
};

//type (0 - long, 1 - short)
export function CardTypeData(value, type = 0) {
	if (type === 0) {
		if (value === CardTypeEnum.Desconocido) return "Desconocido";
		else if (value === CardTypeEnum.Mastercard) return "Mastercard";
		else if (value === CardTypeEnum.Visa) return "Visa";
		else if (value === CardTypeEnum.AmericanExpress) return "American Express";
	} else if (type === 1) {
		if (value === CardTypeEnum.Desconocido) return "Desconocido";
		else if (value === CardTypeEnum.Mastercard) return "MC";
		else if (value === CardTypeEnum.Visa) return "Visa";
		else if (value === CardTypeEnum.AmericanExpress) return "AE";
	} else return "";
}

//type (0 - long, 1 - short)
export function CardBankData(value, type = 0) {
	if (type === 0) {
		if (value === CardBankEnum.Desconocido) return "Desconocido";
		else if (value === CardBankEnum.BankOfAmerica) return "Bank of America";
		else if (value === CardBankEnum.CapitalOne) return "Capital One";
		else if (value === CardBankEnum.OceanBank) return "Ocean Bank";
	} else if (type === 1) {
		if (value === CardBankEnum.Desconocido) return "Desconocido";
		else if (value === CardBankEnum.BankOfAmerica) return "Bank of America";
		else if (value === CardBankEnum.CapitalOne) return "Capital One";
		else if (value === CardBankEnum.OceanBank) return "Ocean Bank";
	} else return "";
}

export function CardData(valueType, valueBank) {
	if (valueType !== CardTypeEnum.Desconocido) {
		if (valueBank !== CardBankEnum.Desconocido) return `(${CardTypeData(valueType, 1)}-${CardBankData(valueBank, 1)})`;
		else return `(${CardTypeData(valueType, 1)})`;
	}
	return "";
}
